export const API_JWT_ACCESS_URL = 'https://sso.trueshape.sg/sso/api/token/'; //JWT access token API
export const API_JWT_VERIFY_URL =
  'https://sso.trueshape.sg/sso/api/token/verify/'; //JWT access token API
export const API_SSO_USER_URL = 'https://sso.trueshape.sg/user/'; //JWT access token API
export const API_PACKAGES_URL = 'https://tscms.trueshape.sg/cms/packages/'; //Packages API
export const API_PACKAGESTATUS_URL =
  'https://tscms.trueshape.sg/cms/packagestatus/'; //Packages API
export const API_PACKAGEUSAGES_URL =
  'https://tscms.trueshape.sg/cms/packageusages/'; //Package Usages API
export const API_CART_URL = 'https://tscms.trueshape.sg/cms/carts/'; //Cart API
export const API_CREATECART_URL = 'https://tscms.trueshape.sg/cms/createcarts/'; //Create Cart API
export const API_CHECKOUT_URL = 'https://tscms.trueshape.sg/cms/checkouts/'; //Treatment API
export const API_CREATECHECKOUT_URL =
  'https://tscms.trueshape.sg/cms/createcheckouts/'; //Checkout API
export const API_PAYMENT_URL = 'https://tscms.trueshape.sg/cms/payment/'; //Payment API
export const API_CUSTOMER_URL = 'https://tscms.trueshape.sg/cms/customers/'; //Customer API
export const API_TREATMENT_URL = 'https://tscms.trueshape.sg/cms/treatment/'; //Treatment API
export const API_ITEM_URL = 'https://tscms.trueshape.sg/cms/items/'; //Item API
export const API_ITEMPURCHASE_URL =
  'https://tscms.trueshape.sg/cms/itempurchases/'; //Item Purchase API
export const API_CATALOG_URL = 'https://tscms.trueshape.sg/cms/catalogs/'; //catalog API
export const API_CREATECATALOG_URL =
  'https://tscms.trueshape.sg/cms/createcatalogs/'; //catalog API
export const API_PAYMENTFEE_URL = 'https://tscms.trueshape.sg/cms/paymentfee/'; //Payment Fee API
export const API_KEYFIELD_URL = 'https://tscms.trueshape.sg/cms/keyfields/'; //Reference Types API
export const API_KEYOBJECT_URL = 'https://tscms.trueshape.sg/cms/keyobjects/'; //Reference Types API
export const API_REFTYPE_URL = 'https://tscms.trueshape.sg/cms/reftypes/'; //Reference Types API
export const API_DROPDOWNOPTION_URL =
  'https://tscms.trueshape.sg/cms/dropdownoptions/'; //Treatment API
export const API_USER_URL = 'https://tscms.trueshape.sg/user/'; //Users API
export const API_PAYMENT_REQUEST =
  'https://tscms.trueshape.sg/cms/payment-request/'; //Hitpay payment request
export const TIMEOUT_SEC = 30; //API timeout setting
export const MODAL_TIMEOUT_SEC = 1; //API timeout setting
